<template>  
    <!-- showPopUpOk --><transition name="fade">
    <div v-show="showPopUpOk" class="modal-overlayfrombottomblur wrapper-todiv" style="z-index: 999;">
      <div class="modal" style="padding-top: 3vh; padding-bottom: 3vh; min-height: 40vh; width: 80%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=80%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizeTitle + 'vh', 'line-height': this.fontSizeTitleLineHeight + 'vh', }">                 
              {{ this.popUpMsgTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': this.fontSizePopUpMsg + 'vh', 'line-height': this.fontSizePopUpMsgLineHeight + 'vh', 'padding-top': '1vh', }" style="overflow-wrap: anywhere;">                 
              {{ this.popUpMsgBody }}
              </div>
            </td>
          </tr>
          <tr v-show="!this.suppressOk">
            <td style="height: 10vh">
              <div><button class="widebtn" ref="show-popup-ok" style="width: 50%; height: 6vh; " @click="this.doPopUpOk();">Ok</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
   <!-- showPopUpTwoOptions --><transition name="fade">
    <div  v-show="showPopUpTwoOptions" class="modal-overlayfrombottomblur  wrapper-todiv" style="z-index: 997;">
      <div class="modal" style="padding-top: 2vh; padding-bottom: 2vh; width: 90%"> 
        <!-- put in table for dev speed need to learn more about flex -->
        <table border=0 width=85%> 
          <tr>
            <td>
              <div class="lt-blue txtc" :style="{ 'font-size': fontSizeNormalInPXToUse * 1.1 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }">                 
              {{ this.PopUpTwoOptionsTitle }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="lt-blue txtc"  :style="{ 'font-size': fontSizeNormalInPXToUse * 0.9 + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" style="overflow-wrap: anywhere; padding-top: 1vh;">                 
               {{ this.PopUpTwoOptionsMessage }}
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-top: 2vh; padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption1();">{{ this.option1ButtonText }}</button></div>
            </td>
          </tr>
          <tr>
            <td>
              <div style="padding-bottom: 2vh;"><button class="widebtn" style="width: 80%; height: 5vh; " :style="{ 'font-size': fontSizeNormalInPXToUse + 'px', 'line-height': fontSizeNormalInPXLineHeightToUse + 'px', }" @click="this.doOption2();">{{ this.option2ButtonText }}</button></div>
            </td>
          </tr>
          <tr> <!-- spacer --> <td><div></div></td>
          </tr>
        </table>       
      </div>
    </div>
  </transition>
  <TippingScreen :key="componentKeyTip" v-if="this.tuid" :tuid='this.tuid' :showGetTipsHereButton='this.showGetTipsHereButton' @return-parent-json="returnJSON"/>
  <!-- NEW COMPONENT IMPORT for JS only -->
  <HomeScreen :key="homeScreenToggle" v-if="showHomeScreen" :tuRedirect='this.redirect' :redirectParam1='this.redirectParam1' :fromScreen='this.fromScreen' :showHomePageFromGoogleAd='this.showHomePageFromGoogleAd' @return-parent-json="returnJSON"/>
  <AccountScreen :key="componentKeyAccount" v-if="showAccount" :bypassDashboard='this.bypassDashboard' @return-parent-json="returnJSON"/>
  <LandingScreen v-if="showLanding" @return-parent-json="returnJSON"/>
  <LoadParseFromHome :key="loadParseToggle" v-if="this.loadParse !== false" :functionName=functionName :paramsObject=paramsObject @return-parent-json="returnParseJSON"/>
  <!-- <LoadLogRocketFromHome :key="loadLogRocketToggle" v-if="this.loadLogRocket !== false" :functionName=functionName :paramsObject=paramsObject @return-parent-json="returnParseJSON"/> -->
  <!-- at the moment LoadLogRocketFromHome is never going to load, doing is all through LoadParseFromHome -->
</template>


<script>
import { defineAsyncComponent } from 'vue'

// so now the below is all async
// import TippingScreen from './components/Tipping.vue'
// import HomeScreen from './components/HomeContent.vue'
// import AccountScreen from './components/AccountPrep.vue'
// import LandingScreen from './components/Landing.vue'


// const Parse = require('parse/node');
// const Parse = require('parse/node');

// import shared from './shared.js';

// import LogRocket from 'logrocket';


export default {
  inject: ['globalPushForwardInterval', 'devEnv', 'appId', 'appJSKey'],
  name: 'App',
  components: {
    TippingScreen: defineAsyncComponent(() =>
      import('./components/Tipping.vue')
    ),
    HomeScreen: defineAsyncComponent(() =>
      import('./components/HomeContent.vue')
    ),
    AccountScreen: defineAsyncComponent(() =>
      import('./components/AccountPrep.vue')
    ),
    LandingScreen: defineAsyncComponent(() =>
      import('./components/Landing.vue')
    ),
    LoadParseFromHome: defineAsyncComponent(() =>
      import('./components/LoadParseFromHome.vue')
    ),
    // LoadLogRocketFromHome: defineAsyncComponent(() =>
    //   import('./components/LoadLogRocketFromHome.vue')
    // )
  },
  data() {
    return {
      showHomeScreen: true,
      showAccount: false,
      showLanding: false,
      tuid: undefined,
      showPopUpOk: false,
      popUpMsgTitle: "",
      popUpMsgBody: "",
      fontSizeTitle: 2.7, // vh
      fontSizeNormal: 2.4, // vh, not currently used
      fontSizeTitleLineHeight: 5, //vh
      fontSizePopUpMsg: 2.3, // vh
      fontSizePopUpMsgLineHeight: 3.4,// vh
      showPopUpTwoOptions: false,
      PopUpTwoOptionsTitle: "",
      PopUpTwoOptionsMessage: "",  
      option1ButtonText: "",
      option2ButtonText: "",
      showingProceedWithVenueConnectMessage: false,
      redirect: "",
      fromScreen: "",
      bypassDashboard: false,
      componentKeyTip: false,
      componentKeyAccount: false,
      loadParse: false,
      // loadLogRocket: false,
      functionName: undefined,
      paramsObject: undefined,
      loadParseToggle: false,
      // loadLogRocketToggle: false,
      showHomePageFromGoogleAd: false,
      homeScreenToggle: false,
      showGetTipsHereButton: false,
      redirectParam1: undefined,
    }
  },
  methods: {
    doPopUpOk(){
      this.showPopUpOk = false;
      // testing Dropbox update
    },
    doOption1(){
      this.showPopUpTwoOptions = false;
      this.resetPopUpTwoOptions();
      if (this.showingProceedWithVenueConnectMessage){
        this.showingProceedWithVenueConnectMessage = false;
        this.showAccount = true;
      }
    },
    doOption2(){
      this.showPopUpTwoOptions = false;
      this.resetPopUpTwoOptions();
      if (this.showingProceedWithVenueConnectMessage){
        this.showingProceedWithVenueConnectMessage = false;
        console.log("getting rid of localStorage items");
        window.localStorage.removeItem("tuconnecttovenue");
        window.localStorage.removeItem("tuconnecttovenueinprocess"); 
        if (window.localStorage.getItem("willConnectRealUserDetails") !== null) {
          // window.localStorage.removeItem("willConnectRealUserDetails");
          window.localStorage.removeItem("willConnectRealUserDetailsinprocess"); 
          window.localStorage.setItem("willConnectRealUserDetailsjustcancelled", "yes"); 
        } 
        this.tuid = undefined;
        this.showHomeScreen = true;
        this.homeScreenToggle = !this.homeScreenToggle;;
        // console.log("tuconnecttovenueinprocess removed");
        
      }
    },
    resetPopUpTwoOptions(){

      this.PopUpTwoOptionsTitle = "";
      this.PopUpTwoOptionsMessage = "";
      this.option1ButtonText = "";
      this.option2ButtonText = "";
      
    },
    returnParseJSON(returnJSON){
    
      // console.log("JSON returnParseJSON on App ::: " + JSON.stringify(returnJSON));

      if (returnJSON.functionName === "validateUserLinkDetails"){
        // console.log("I am about to getURLDataIfAnyAndSetPart2");
        this.getURLDataIfAnyAndSetPart2(returnJSON);
      } else if (returnJSON.functionName === "fetchUserDetails"){
        
        window.localStorage.setItem("tuisrecipient", returnJSON.isRecipient);

        this.doPostGetUserDataIfNecessary();
        

        ////console.log("isRecipient::: " + window.localStorage.getItem("tuisrecipient"));

        // this.popUpMsgTitle = "Great";
        // this.popUpMsgBody = "FETCHED isRecipient:: " + window.localStorage.getItem("tuisrecipient");
        // this.showPopUpOk = true;

      } else {
        // console.log(returnJSON.functionName + "completed with return value: " + JSON.stringify(returnJSON, null, 2));
      }
    
    },

    returnJSON(returnJSON){


      // console.log("JSON on App ::: " + JSON.stringify(returnJSON));
      
      if (returnJSON.selectedTUID){
        
        ////console.log("this.showHomeScreen::: " +  returnJSON.showHomeScreen);

        this.showHomeScreen = returnJSON.showHomeScreen;
        this.showAccount = false;

        this.componentKeyAccount = !this.componentKeyAccount;

        this.tuid = returnJSON.selectedTUID;

        this.componentKeyTip = !this.componentKeyTip;

        // this.tuid = returnJSON.selectedTUID;

        //couldn't get to the bottom of this - for some reason the symchonrous call above would cause the homescreen to show again but WITHOUT triggering renderTriggered. I only know because it DOES fire created() - so this tiny timeout is enough for this.showHomeScreen = returnJSON.showHomeScreen; to hide the main screen without this.tuid = returnJSON.selectedTUID for some strange reason causing it to show again 

        // I actually think the reason for this was we needed component key - I have just confirmed that is the case

        // setTimeout(() =>  {
        //   ////console.log("timout returnJSON.selectedTUID ::: " + returnJSON.selectedTUID);
        //   // window.localStorage.setItem("tuid", returnJSON.selectedTUID); 
        //   // return;
        //   this.tuid = returnJSON.selectedTUID;
        //   } , 5);

        

        // // /////console.log("App tuid::: " + this.tuid);
        // /////console.log("App showHomeScreen::: " + this.showHomeScreen);
      } else if (returnJSON.showAccount){

        ////console.log("App.vue is showing account");
        this.showHomeScreen = returnJSON.showHomeScreen;
        if (returnJSON.bypassDashboard) {
          ////console.log("returnJSON.bypassDashboard: " + returnJSON.bypassDashboard);
          this.bypassDashboard = true;
        }
        this.showAccount = true;
        
      } else if (returnJSON.showHomeScreen){

        console.log("doing this");

        this.showAccount = false; 
        if (returnJSON.redirect !== undefined) {

          if (returnJSON.redirect === "showLoggedOut") {
            this.popUpMsgTitle = "That's a wrap..";
            this.popUpMsgBody = "You have successfully logged out. Tap the account icon top right to log back in again.";
            this.showPopUpOk = true;
          } else { 
            // console.log("returnJSON.fromScreen: " + returnJSON.fromScreen);
            this.redirect = returnJSON.redirect;
            this.fromScreen = returnJSON.fromScreen;
            // console.log("this.fromScreen: " + this.fromScreen);
          }
        }

        this.tuid = undefined;

        ////console.log("LOCALSTORAGE: " + JSON.stringify(window.localStorage));

        if (returnJSON.redirect === undefined && window.localStorage.getItem("tuisrecipient") !== null && window.localStorage.getItem("tuisrecipient") === "true") {
          console.log("going to ACCOUNT as RECIPIENT");
          // alert("going to ACCOUNT");

          if (window.localStorage.getItem("strConnAccId") !== null){
            // fine
          } else {
            this.bypassDashboard = true;
          }
          this.showHomeScreen = false;
          this.showAccount = true;

          this.componentKeyAccount = !this.componentKeyAccount;
        
        } else if (window.localStorage.getItem("willConnectRealUserDetails") !== null && window.localStorage.getItem("willConnectRealUserDetailsjustcancelled") === null ) {
          console.log("showing Account from APP because of willConnectRealUserDetails");
          this.showHomeScreen = false;
          this.showAccount = true;
        } else if (window.localStorage.getItem("masterregisteringqrcode") !== null) {
          ////console.log("showing Account from APP because of masterregisteringqrcode");
          this.showHomeScreen = false;
          this.showAccount = true;
        
        } else {
          // alert("STAYING NORMAL");
          console.log("SHOWING NORMAL");

          if (window.localStorage.getItem("willConnectRealUserDetailsjustcancelled") !== null){
            console.log("CLEARLING ALL LOCAL STORAGE");
            window.localStorage.clear(); // remove the lot they can start again
          } 

          this.showAccount = false;
          this.showHomeScreen = returnJSON.showHomeScreen;
        }

      }
    },
    doIt(){
      this.tuid = '00000000002';
    },
    getTUID(){

      ////console.log("window.location.search: " + window.location.pathname);

      if (window.location.pathname === "/jw") {
        this.showHomeScreen = false;
        this.tuid = "00000000435";
        return;
      } else if (window.location.pathname === "/jwc") {
        this.showHomeScreen = false;
        this.tuid = "00000000800";
        return;
      }

      let urlParams = new URLSearchParams(window.location.search);
      // /////console.log(urlParams.has('tuid')); // true

      if (urlParams.has('tuid')){
        
        if (window.localStorage.getItem("willConnectRealUserDetails") !== null) {
          console.log("showing Account because of willConnectRealUserDetails");
          this.showHomeScreen = false;
          this.showAccount = true;
        } else if (window.localStorage.getItem("masterregisteringqrcode") !== null) {
          ////console.log("showing Account because of masterregisteringqrcode");
          this.showHomeScreen = false;
          this.showAccount = true;
        } else {
          this.showHomeScreen = false;
          // window.localStorage.setItem("tuid", urlParams.get('tuid'));  was thinking about it but don't need it
          this.tuid = urlParams.get('tuid');
      
        }
      } 
      if (urlParams.has('connect')){
        this.showGetTipsHereButton = true;
      }

    },
    async deployLogRocket(userObjectId){

      
      // var LRDisplayname = "LR user activating";

      // if (window.localStorage.getItem("tudis") !== null) {
      //   LRDisplayname = window.localStorage.getItem("tudis") + " activating";
      // }

      // try {


        // console.log(":about to Logrocket");

        // app.component("LogRocket", LogRocket);



        //  if (!app._context.components[componentName]) {
        //       app.component(
        //           componentName,
        //           defineAsyncComponent(() => import('logrocket')),
        //       );
        //   }

        // const LogRocket = defineAsyncComponent( async () =>
        //   await import('logrocket')
        // )

        // const LogRocket = async () => await import('logrocket');

        // const LogRocket = eval(require('logrocket'));

        // const LogRocket = await import('logrocket');

        // console.log("Logrocket IS: " + JSON.stringify(LogRocket, null, 2));

        // LogRocket.init('fpi3vr/thanku'); //LogRocket.init('thanku-alt-3/thanku-alt-3'); // LogRocket.init('thanku-alt-2/thanku-alt-2');//LogRocket.init('thanku-alt/tualt'); // LogRocket.init('thanku-alt-4/thanku-alt-4'); // 

        

        // console.log("ABLE TO INIT LOGROCKET!!!!!!!!!!!!!!!!!!!!");

        // LogRocket.identify(userObjectId, {
        //   name: LRDisplayname,
        //   email: undefined,
        //   // Add your own custom user variables here, ie:

        // });
      // } catch (e) {
      //   ////console.log("error starting LogRocket on App vue for user id: " + userObjectId);
        
      //   const params = {
      //     toEmail: "appalert@thanku.app",
      //     subject: "ALERT! LogRocket initialisation error: " + e.message,
      //     body: "ALERT! LogRocket initialisation error: " + e.message,
      //   };

      //   this.functionName = "sendEmail";
      //   this.paramsObject = params;
        
      //   this.loadParse = true;

      //   // fail silently do nothing
      // }
    },
    async getURLDataIfAnyAndSet(){

      console.log("PRE PRE PRESTARTING getURLDataIfAnyAndSet");

      let doingAsyncAction = false;

      try {

        console.log("STARTING getURLDataIfAnyAndSet");
        // get URL params
        let urlParams = new URLSearchParams(window.location.search);

        console.log("should have urlparams coming...");

        console.log("urlParams ON APP:: " + urlParams);

        let referrerURL = (document.referrer !== undefined && document.referrer !== null && document.referrer !== "") ? document.referrer : "n/a";
        window.localStorage.setItem("referrerURL", referrerURL);

        // console.log("on App.vue referrerURL is: " + referrerURL);

        // /////console.log(urlParams.has('tu')); // true

         if (urlParams.has('rgla')){
          if (urlParams.get('rgla') === "1"){

            
            // console.log("rgla = 1 SO doLoadLogRocket!!!!");

            let LRObjectId = undefined;

            if (window.localStorage.getItem("tuob") !== null) {
              LRObjectId = window.localStorage.getItem("tuob");
            } else if (window.localStorage.getItem("logRocketUserTimeStampId") !== null) {
              LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
            } else {
              window.localStorage.setItem("logRocketUserTimeStampId", new Date().getTime());
              LRObjectId = window.localStorage.getItem("logRocketUserTimeStampId");
            }

            this.showHomePageFromGoogleAd = true;
            this.homeScreenToggle = !this.homeScreenToggle;

            if (window.localStorage.getItem("beenFromGoogleAdsAlready") !== null && window.localStorage.getItem("beenFromGoogleAdsAlready") === "yes") {

              let referrerURL = (document.referrer !== undefined && document.referrer !== null && document.referrer !== "") ? document.referrer : "n/a";
              window.localStorage.setItem("googleAdsOriginalReferrerURL", referrerURL);


              let numTimes = "0";

              if (window.localStorage.getItem("beenFromGoogleAdsAlreadyNumTimes") !== null){
                numTimes = +window.localStorage.getItem("beenFromGoogleAdsAlreadyNumTimes");
                numTimes += 1;
                window.localStorage.setItem("beenFromGoogleAdsAlreadyNumTimes", numTimes.toString());
              }

              let params = {
                toEmail: "appalert@thanku.app",
                subject: "NB, user from Google Ads reloaded / revisited page",
                body: {
                  type: "GoogleAdsRepeatTime",
                  numberOfTimes: numTimes,
                  googleAdsOriginalReferrerURL: window.localStorage.getItem("googleAdsOriginalReferrerURL"),
                  referrerURL: window.localStorage.getItem("referrerURL"),
                  LRObjectId: LRObjectId
                },
              };
              console.log("about to load PARSE 2");
              this.loadParse = false;
              this.loadParseToggle = !this.loadParseToggle;
              this.functionName = "sendEmail";
              this.paramsObject = params;
              this.loadParse = true;

            } else {
              // 'rgla' is referrer Google Ads

              // // https://googleads.g.doubleclick.net/ was an unreliable means of detection

              // referrerUrl local is set elsewhere
              window.localStorage.setItem("beenFromGoogleAdsAlready", "yes");
              window.localStorage.setItem("beenFromGoogleAdsAlreadyNumTimes", "1");

              let params = {
                toEmail: "appalert@thanku.app",
                subject: "user from Google Ads first time load",
                body: {
                  type: "GoogleAds1stTime",
                  numberOfTimes: 1,
                  referrerURL: window.localStorage.getItem("referrerURL"),
                  LRObjectId: LRObjectId
                },
              };
              console.log("about to load PARSE 1");
              this.loadParse = false;
              this.loadParseToggle = !this.loadParseToggle;
              this.functionName = "sendEmail";
              this.paramsObject = params;
              this.loadParse = true;
              this.showHomePageFromGoogleAd = true;
              this.homeScreenToggle = !this.homeScreenToggle;

              console.log("this.showHomePageFromGoogleAd: "+ this.showHomePageFromGoogleAd);
            }

          } else {
            this.showHomePageFromGoogleAd = false;
            this.homeScreenToggle = !this.homeScreenToggle;
          }
          
        } else {
          // this is the one which will be triggered
          this.showHomePageFromGoogleAd = false;
          this.homeScreenToggle = !this.homeScreenToggle;
        }
        

        if (urlParams.has('showlanding')){
          if (urlParams.get('showlanding') === "yes"){
            ////console.log("showing LANDING!");
            this.showLanding = true;
          }
        }

        if (urlParams.has('returnfromstripeonboarding')){
          if (urlParams.get('returnfromstripeonboarding') === "yes"){
            // console.log("returnfromstripeonboarding!");
            window.localStorage.setItem("returnfromstripeonboarding", "yes");
            this.bypassDashboard = true;
          }
        }

        if (window.localStorage.getItem("localIsDoingStripeOnboarding") != null) {
          // means the user might, for example, have simply clicked the back button on browser when presented with Stripe onboarding
          window.localStorage.removeItem("localIsDoingStripeOnboarding");
          if (window.localStorage.getItem("returnfromstripeonboarding") !== null){
            // no need to worry already doing it
          } else {
            // console.log("localIsDoingStripeOnboarding => returnfromstripeonboarding!");
            window.localStorage.setItem("returnfromstripeonboarding", "yes");
            this.bypassDashboard = true;
          }

        }

        if (urlParams.has('refreshstripeonboardinglink')){
          if (urlParams.get('refreshstripeonboardinglink') === "yes"){
            // console.log("refreshstripeonboardinglink!");
            window.localStorage.setItem("refreshstripeonboardinglink", "yes");
            this.bypassDashboard = true;
          }
        }

         if (urlParams.has('mustDoKYC')){
          if (urlParams.get('mustDoKYC') === "yes"){
            // console.log("mustDoKYC!");
            window.localStorage.setItem("mustDoKYC", "yes");
            this.bypassDashboard = true;
          }
        }

        // this is now being done in the router to stop component loading before redirect
        if (urlParams.has('linkname')){
          // console.log("we are doing this here LINKNAME");
          if (urlParams.get('linkname') === "ggreview"){
            doingAsyncAction = true;
            let params = {
              linkName: urlParams.get('linkname'),
              userObjectId: urlParams.get('tuob'),
            }
            this.loadParse = false;
            this.loadParseToggle = !this.loadParseToggle;
            this.functionName = "ggreview";
            this.paramsObject = params;
            this.loadParse = true;
            // sharedhome.doParseServer('ggreview', params, this.appId, this.appJSKey);
          }
        }

        if (urlParams.has('tu') && urlParams.has('tuob')){
          // migrating users need both
          // console.log("setting user details from link");
          
          // await this.deployLogRocket(urlParams.get('tuob')); TODO Put this back in

          let urlParams = new URLSearchParams(window.location.search);

          if (urlParams.has('tu') && urlParams.has('tuob')){

            if (urlParams.get("tuob") === "GJKuIWpV63") {
                const params = {
                  toEmail: "appalert@thanku.app",
                  subject: "Anouk got to link activation",
                  body: ""
                };

                this.loadParse = false;
                this.loadParseToggle = !this.loadParseToggle;
                this.functionName = "sendEmail";
                this.paramsObject = params;
                this.loadParse = true;

            } 
          }

          ////console.log("MIGRATING USER");
          doingAsyncAction = true;        
          let params = {
            tu: urlParams.get('tu'),
            tuob: urlParams.get('tuob'),
          }
          this.loadParse = false;
          this.loadParseToggle = !this.loadParseToggle;
          this.functionName = "validateUserLinkDetails";
          this.paramsObject = params;
          this.loadParse = true;

          ////console.log("deployed LogRocket");

          ////console.log("sending params: " + JSON.stringify(params, null, 2))

        } 
        
        if (doingAsyncAction === false) {
          this.doFinishLoading();
        }

      } catch (err) {
          console.log("ERROR in getURLDataIfAnyAndSet:: " + err.message);
          throw err;
      }
    },
    async getURLDataIfAnyAndSetPart2(returnJSON){

      let suppressMessage = "no";

      let selectUserData = returnJSON;

      

      //let urlParams = new URLSearchParams(window.location.search); won't work anymore already consumed

      //console.log("urlParams on getURLDataIfAnyAndSetPart2 are: " + JSON.stringify(urlParams, null, 2))

      try{

        // const selectUserData = await sharedhome.doParseServer('validateUserLinkDetails', params, this.appId, this.appJSKey);

        // console.log("retrieved selectedUserData:: " + JSON.stringify(selectUserData, null, 2));

        // /////console.log("match result: " + result);
        if (selectUserData.status === "ok") {

          // console.log("successfully activated user");

          ////console.log("selectUserData.suppressMessage: " + selectUserData.suppressMessage);
        
          if (selectUserData.suppressMessage !== "yes") {
            this.popUpMsgTitle = "Great news..";
            this.popUpMsgBody = "Your thankU account has been successfully activated on this device";
            this.showPopUpOk = true;
            suppressMessage = "";
          } else {
            suppressMessage = "USER MESSAGE WAS SUPPRESSED - ";
          }
          
          let tuTemp = window.localStorage.getItem("tu");
          let tuobTemp = window.localStorage.getItem("tuob");

          window.localStorage.clear();

          window.localStorage.setItem("tu", tuTemp);
          window.localStorage.setItem("tuob", tuobTemp);
          window.localStorage.setItem("tudis", selectUserData.displayname);
          window.localStorage.setItem("tuisfulluser", true); // must be since we had a valid login session token
          window.localStorage.setItem("tuhasstconnaccid", (selectUserData.strConnAccId !== undefined));
          window.localStorage.setItem("tucurrcode", selectUserData.currencyCode);
          window.localStorage.setItem("tuhasstconnaccextid", (selectUserData.strConnAccExternalAccountId !== undefined));
          window.localStorage.setItem("tuisrecipient", selectUserData.isRecipient);

          if (selectUserData.isRecipient === true) {

            if (window.localStorage.getItem("tuhasstconnaccid") === null || window.localStorage.getItem("tuhasstconnaccextid") === null) {
              // this.showPlaceholderAccountPage = false;

              // console.log("mustConnectToBankAccount is firing, tuhasstconnaccid is " + window.localStorage.getItem("tuhasstconnaccid") + " and tuhasstconnaccextid is " + window.localStorage.getItem("tuhasstconnaccextid"));
              this.mustConnectToBankAccount = true;
            } else {
              // console.log("IS NOT FIRING");
            }

            // if (selectUserData.strConnAccId !== undefined) {
            //   window.localStorage.setItem("strConnAccId", selectUserData.strConnAccId);
            // }
          } else {
            // console.log("really not firing");
          }


          const params = {
            toEmail: "appalert@thanku.app",
            subject: suppressMessage + "Great! " + selectUserData.displayname + " id: "  + window.localStorage.getItem("tuob") + " activated their account on the web app",
            body: suppressMessage + "Great! " + selectUserData.displayname + " id: "  + window.localStorage.getItem("tuob") + " activated their account on the web app"
          };

          this.loadParse = false;
          this.loadParseToggle = !this.loadParseToggle;
          this.functionName = "sendEmail";
          this.paramsObject = params;
          this.loadParse = true;
          // sharedhome.doParseServer("sendEmail", params, this.appId, this.appJSKey);
          
          this.doFinishLoading();

          // /////console.log("Details: " + window.localStorage.getItem("tu") + " " + window.localStorage.getItem("tuob"));

        } else {
          ////console.log("couldn't activate user: " + e.message);

          let userObjectId = window.localStorage.getItem("tuob") ? window.localStorage.getItem("tuob") : "unknown Id";
          const params = {
            toEmail: "appalert@thanku.app",
            subject: "ALERT! couldn't activate QR user: " + userObjectId,
            body: "",
          };
          this.loadParse = false;
          this.loadParseToggle = !this.loadParseToggle;
          this.functionName = "sendEmail";
          this.paramsObject = params;
          this.loadParse = true;

          // sharedhome.doParseServer("sendEmail", params, this.appId, this.appJSKey);

          this.popUpMsgTitle = "Sorry";
          this.popUpMsgBody = "thankU couldn't match your details from that link. Please copy the link and email it to tech@thanku.app. We apologise for the inconvenience.";
          this.showPopUpOk = true;

          this.doFinishLoading();
        }


      } catch (e) {
        throw e;
      }
    },
    doRedirects(){

      var currentRedirect = this.redirect;

      let urlParams = new URLSearchParams(window.location.search);

      if (urlParams.get('tuid') === "00000001933"){
        this.redirectParam1 = "Body Image";
        this.redirect = "tipwithoutscanning";
        console.log("came through here :-)");
      }  

      // ////console.log("START redirects:" + window.location.href);

      if (window.location.href.indexOf("/nhbfmembers") > -1){
        this.redirect = "nhbf"; // I was passing this instance var thorugh the prop to HomeContent :tuRedirect='this.redirect' - problem is that value of showHomeScreen starts as true, therefore the prop gest passed on creation
        this.showHomeScreen = true; 
        
      } else if (window.location.href.indexOf("/contact") > -1) {
        this.redirect = "contact";
      } else if (window.location.href.indexOf("/about") > -1) {
        this.redirect = "about";
      } else if (window.location.href.indexOf("/terms") > -1) {
        this.redirect = "terms";
      } else if (window.location.href.indexOf("/privacy") > -1) {
        this.redirect = "privacy";
      } else if (window.location.href.indexOf("/givetips") > -1) {
        this.redirect = "givetips";
      } else if (window.location.href.indexOf("/givetipsmoreinfo") > -1) {
        this.redirect = "givetipsmoreinfo";
      } else if (window.location.href.indexOf("/tipwithoutscanning") > -1) {
        this.redirect = "tipwithoutscanning";
       } else if (window.location.href.indexOf("/gettipsstart") > -1) {
        // NB this must come before gettips otherwise that will be found incorrectly first
        this.redirect = "gettipsstart";

      } else if (window.location.href.indexOf("/gettips") > -1) {
        this.redirect = "gettips";
     
      } else if (window.location.href.indexOf("/gettipsmoreinfo") > -1) {
        this.redirect = "gettipsmoreinfo";
      } else if (window.location.href.indexOf("/testimonials") > -1) {
        this.redirect = "testimonials";
      } else if (window.location.href.indexOf("/salons") > -1) {
        ////console.log("REDIRECT TO SALONS!" );
        this.redirect = "salons";
      } else if (window.location.href.indexOf("/unsubscribe") > -1) {
        ////console.log("REDIRECT TO unsubscribe!" );

        let urlParams = new URLSearchParams(window.location.search);

        ////console.log("urlParams: " + JSON.stringify(urlParams, null, 2));

        if (urlParams.has('em')){
          let email = urlParams.get('em');
          window.localStorage.setItem("emailToUnsubscribe", email);
        }

        this.redirect = "unsubscribe";
      }

      if (currentRedirect === this.redirect) {
        return false;
      } else {
        return true;
      }


    }, 
    getUserDataIfNecessary(){


      // console.log("starting getUserDataIfNecessary"); 

      let urlParams = new URLSearchParams(window.location.search);

      if (urlParams.has('tu') && urlParams.has('tuob')){

        if (urlParams.get("tuob") === "GJKuIWpV63") {
            const params = {
              toEmail: "appalert@thanku.app",
              subject: "Anouk got to getUserDataIfNecessary",
              body: ""
            };

            this.loadParse = false;
            this.loadParseToggle = !this.loadParseToggle;
            this.functionName = "sendEmail";
            this.paramsObject = params;
            this.loadParse = true;

        } 
      }


      // this is to refresh expected user values in case the user logged in etc before a given value starting being required by the front end code
      try {
        if (window.localStorage.getItem("tuob") !== null && window.localStorage.getItem("tuisfulluser") !== null) {

          // ////console.log("getUserDataIfNecessary tuob::: " + window.localStorage.getItem("tuob"));
          // we have a proper user, check they have all the object values they should
          if (window.localStorage.getItem("tuisrecipient") === null || window.localStorage.getItem("tuisrecipient") === undefined || window.localStorage.getItem("tuisrecipient") === "undefined" ) {

            // console.log("getUserDataIfNecessary tuisrecipient was null");

            let params = {
              userObjectId: window.localStorage.getItem("tuob"),
            }
            this.loadParse = false;
            this.loadParseToggle = !this.loadParseToggle;
            this.functionName = "fetchUserDetails";
            this.paramsObject = params;
            this.loadParse = true;

            // takes us off into async situation

            // const userData = await sharedhome.doParseServer("fetchUserDetails", params, this.appId, this.appJSKey);
            
          } else {

            // console.log("getUserDataIfNecessary tuisrecipient was NOT null");

            // can go straight on
            this.doPostGetUserDataIfNecessary();

            ////console.log("getUserDataIfNecessary ELSE::: " );
            // this.popUpMsgTitle = "Great";
            // this.popUpMsgBody = "isRecipient:: " + window.localStorage.getItem("tuisrecipient");
            // this.showPopUpOk = true;
          }
          // return true; // doesnt do anything now
        } else {
          // console.log("condition not met");
          this.doPostGetUserDataIfNecessary();
        }


      } catch (e) {
        this.popUpMsgTitle = "Oops";
        this.popUpMsgBody = "thankU ran into a problem: " + e.message + " in getUserDataIfNecessary. Please copy this message and email it to tech@thanku.app. We apologise for the inconvenience.";
        this.showPopUpOk = true;
      }
    },
    doPostGetUserDataIfNecessary(){

      // console.log("doPostGetUserDataIfNecessary yup");

      this.getURLDataIfAnyAndSet(); // no longer is an await because async doesn't work with considtional Parse loading

    },  
  
    doFinishLoading(){

      // if (window.localStorage.getItem('tuob') !== undefined){

      //   if (window.localStorage.getItem('tuob') === "GJKuIWpV63") {
      //       const params = {
      //         toEmail: "appalert@thanku.app",
      //         subject: "Anouk got to doFinishLoading",
      //         body: ""
      //       };

      //       this.loadParse = false;
      //       this.loadParseToggle = !this.loadParseToggle;
      //       this.functionName = "sendEmail";
      //       this.paramsObject = params;
      //       this.loadParse = true;

      //   } 
      // }


      // separate end section because  we can get to it in two different ways, getURLDataIfAnyAndSet and getURLDataIfAnyAndSetPart2
      if (window.localStorage.getItem("willConnectRealUserDetails") !== null) {

        if (window.localStorage.getItem("willConnectRealUserDetailsinprocess") !== null) {
          // 
          // console.log("willConnectRealUserDetailsinprocess WAS NOT NULL");

          window.localStorage.setItem("tuconnecttovenue", "bankAccountOnly"); // in testing we lost it somewhere

          this.showingProceedWithVenueConnectMessage = true;
          this.PopUpTwoOptionsTitle = "Continue setting up?";
          this.PopUpTwoOptionsMessage = "You were in the process of setting up your thankU account. Tap 'Connect' to complete the process or 'No thanks' otherwise. You can restart the process anytime by scanning your thankU QR code."; // different message because they are already connected
          this.option1ButtonText = "Connect";
          this.option2ButtonText = "No thanks";
          this.showHomeScreen = false;
          this.showPopUpTwoOptions = true;
        } else {
          // /////console.log("showing Account because of willConnectRealUserDetails");
          this.showHomeScreen = false;
          this.showAccount = true;
        }

      } else if (window.localStorage.getItem("tuconnecttovenue") !== null && window.localStorage.getItem("tuconnecttovenue") !== "bankAccountOnly") {

        if (window.localStorage.getItem("tuconnecttovenueinprocess") !== null) {
          
          // console.log("tuconnecttovenueinprocess WAS NOT NULL");

          this.showingProceedWithVenueConnectMessage = true;
          this.PopUpTwoOptionsTitle = "Continue connecting?";
          const venueName = window.localStorage.getItem("tuconnecttovenuename") !== null ? " at " + window.localStorage.getItem("tuconnecttovenuename") : "";
          this.PopUpTwoOptionsMessage = "You were in the process of connecting your thankU account" + venueName + ". Tap 'Connect' to complete the process or 'No thanks' otherwise. You can restart the process anytime by scanning one of the thankU QR badges where you work and tapping 'Get tips here...' at the bottom of the list.";
          this.option1ButtonText = "Connect";
          this.option2ButtonText = "No thanks";
          this.showHomeScreen = false;
          this.showPopUpTwoOptions = true;
        } else {
          // /////console.log("showing Account because of tuconnecttovenue");
          this.showHomeScreen = false;
          this.showAccount = true;
        }
      } else if (window.localStorage.getItem("tuisrecipient") !== null && this.tuid === undefined){ // not a tuid link
        ////console.log("I am a recipient: " + window.localStorage.getItem("tuisrecipient"));

          // if (window.localStorage.getItem('tuob') !== undefined){

          //   if (window.localStorage.getItem('tuob') === "GJKuIWpV63") {
          //       const params = {
          //         toEmail: "appalert@thanku.app",
          //         subject: "Anouk got to direction to load Dashboard",
          //         body: ""
          //       };

          //       this.loadParse = false;
          //       this.loadParseToggle = !this.loadParseToggle;
          //       this.functionName = "sendEmail";
          //       this.paramsObject = params;
          //       this.loadParse = true;

          //   } 
          // }
          this.showHomeScreen = false;
          this.showAccount = true;
      }
    },
  },

  async created(){

    let urlParams = newFunction();

    if (urlParams.has('tu') && urlParams.has('tuob')){

      if (urlParams.get("tuob") === "GJKuIWpV63") {
          const params = {
            toEmail: "appalert@thanku.app",
            subject: "Anouk got to app created",
            body: ""
          };

          this.loadParse = false;
          this.loadParseToggle = !this.loadParseToggle;
          this.functionName = "sendEmail";
          this.paramsObject = params;
          this.loadParse = true;

      }  
    }
    // console.log("this.devEnv::::::::::::::::", this.devEnv);
    // console.log("this.appId::::::::::::::::", this.appId);

    // console.log("this.fromScreenAPP: " + this.fromScreen);
    
    if (window.localStorage.getItem("tuob") !== null & window.localStorage.getItem("tuob") === "M1t7NvDnNd"){
      window.localStorage.removeItem("tuob"); // account deleted (Nicola accidental at Total Hair Concept)
    }

    if (window.localStorage.getItem("tuob") !== null & window.localStorage.getItem("tuob") === "FFPgaN56n9"){
      window.localStorage.removeItem("tuob"); // account deleted (Rosamund accidental at HARI's South Ken)
    }

    if (window.localStorage.getItem("tuob") !== null & window.localStorage.getItem("tuob") === "QgFSN60KjX"){
      window.localStorage.removeItem("tuob"); // account deleted (Gintare) accidental sign up at Edward James Balham
    }

    if (window.localStorage.getItem("tuob") !== null & window.localStorage.getItem("tuob") === "UorgmuYUlM"){
      window.localStorage.removeItem("tuob"); // 'Talky' didnt want an acount, just to tip..
    }
    

    

    // const Chart = await import('chart.js');
    // console.log("MOUNTED Chart IS: " + JSON.stringify(Chart, null, 2));

    // const Parse = require('parse/node');
    // can't console as object is circular

    // const params = {
    //   toEmail: "appalert@thanku.app",
    //   subject: "qwerawerawe",
    //   body: "snitter app"
    // };

    // await sharedhome.doParseServer();
    // Parse.serverURL = 'https://parseapi.back4app.com/';

    // Parse.initialize("a9Xshs3en6NY95t9FyvBKl2OyAoYcLuvkWEz4Kkh", "gsVcJlxD6IrK9bePNlYtwqCvvsZ0xwAtLzmz0C6d"); 

    // Parse.Cloud.run("sendEmail", params);

    // const MobileDetect = await import('mobile-detect');

    // console.log("MOUNTED MobileDetect IS: " + JSON.stringify(MobileDetect, null, 2));

    // const LogRocket = await import('logrocket');

    // console.log("MOUNTED Logrocket IS: " + JSON.stringify(LogRocket, null, 2));

 

    // /window.localStorage.removeItem("tuuserpath");

    // ////console.log("GLOBAL? " + this.$isLoading);

    // ////console.log("is online? " + window.navigator.onLine);

    // window.localStorage.clear();

    // ////console.log("GLOBAL? " + this.$isLoading);
    
    if (window.localStorage.getItem("tuob") !== null){
      ////console.log("CREATED!!! with " + window.localStorage.getItem("tuob"));
    }

    if (this.doRedirects() === true){ // need to do this first before everything has loaded. Prop only gets passed at creatTime - not even sure why this actually is working - alternative strategy to use localStorage
      ////console.log("we redirected");
      return;
    } else {
      ////console.log("NO REDIRECTS HERE!");
    }


    ////console.log("created about to getTUID");
    this.getTUID();
    ////console.log("CREATED about to getUserDataIfNecessary");

    let myREALParams = new URLSearchParams(window.location.search);
    // console.log("myREALParams: " + myREALParams);

    this.getUserDataIfNecessary();  // can no longer be await because of the conditional Parse load everythign has to happen in sequence

    



    function newFunction() {
      return new URLSearchParams(window.location.search);
    }
    // window.localStorage.clear();

    // this.doFinishLoading();



    // /////console.log("this.globalPushForwardInterval:: " + this.globalPushForwardInterval);
    // sharedhome.saveToUserPath(this.devEnv, "user started " + new Date().toISOString(), this.globalPushForwardInterval); this achieves nothing

  }, 
  async mounted(){

    // alert("APP " + window.localStorage.getItem("willConnectRealUserDetails"));
   
    // this.loadParse = true;

    // window.addEventListener("scroll", this.doLoadParse);
    
    // window.setTimeout(this.doLoadParse, 5000);
  },
}
</script>

<style>

/* @font-face {
    font-family: "DIN Next LT Pro";
    src: local("LT"), 
      url(./fonts/DINNextLTPro-Regular.ttf) format("truetype");
    font-weight: normal;
    font-style: normal;
}
*/

/* this is actually operating */
#app { 
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  color: #000066;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  /* color: #2c3e50; */
  margin-top: 60px;
}

/* .bg-primary {
    background: #95AEED;
}  */

.background{
    background-color: #EDF2F7;  
}
/* background-color: #E5E5E5; */
.wrapper-todiv {
  margin: auto;
  width: 91%;
  min-width: 50px; /* Will be AT LEAST 20em wide */
  max-width: 400px; 
  /* background: rgb(207, 54, 156); */
  z-index: 501;
  background-color: transparent;
  /* outline:0.01em solid black;   */
}



.tulogo{
  position: relative;
  height: clamp(2px, 7vh, 64px);
  align-content: center;
  cursor:pointer;
}

.poweredbystripe{
  /* position: relative; */
  width: 35%;
  align-content: center;
}


.primary-text-title{
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 22px;
  line-height: 35px;
  align-items: center;
  letter-spacing: 0.25px;
  color: #000066;
}

.lt-blue{
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  letter-spacing: 0.25px;
  color: #000066;
}

.divl{
  text-align: left;
}

.divc{
  text-align: center;
}

.divr{
  text-align: right;
}

.tb-padding{
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.txtl{
  align-items: left;
}

.txtc{
  align-items: center;
}

.txtr{
  align-items: right;
}


.primarytextmid{
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  align-items: center;
  letter-spacing: 0.35px;
  color: #000066;
}

.primarytextmidcentre{
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 30px;
  align-items: center;
  letter-spacing: 0.35px;
  color: #000066;
}

.primary-general-text{
  font-family: "Roboto";
  font-style: bold;
  font-weight: normal;
  font-size: 16px;
  line-height: 30px;
  letter-spacing: 0.35px;
  color: #000066;   /* 000066 */
}

.notes-text{
  font-size: 12px;  
  width: 90%; 
  margin: 0 auto; 
  line-height: 15px;
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.text-only-button{
  font-family: "Roboto";
  font-style: bold;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  border: none;
  outline: none; 
  padding: 30;
  background: none; 
  cursor:pointer;
  color: #000066;  
}

.cancel{
  font-family: "Roboto";
  font-style: bold;
  letter-spacing: 0.35px;
  padding: 0;
  color: #95AEED;
}

.padded{
  font-family: "Roboto";
  font-style: bold;
  letter-spacing: 0.35px;
  padding: 30;
  color: #95AEED;
}

.userinfoframe {
  position: relative;
  width: 100%;
  /* height: 80px; */
  display: flex;
  /* WIDTH and HEIGHT are required */
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 20px;
}

.userinfoframenowidth {
  position: relative;
  /* height: 80px; */
  display: flex;
  /* WIDTH and HEIGHT are required */
  justify-content: center;
  align-items: center;
  background: #FFFFFF;
  border-radius: 20px;
}

.infolabel {
  margin: auto;
  position: relative;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 4vh;
  width: 100%;
  min-width: 50px;
  max-width: 440px;
  background: #E2E8F0; /* background: #E2E8F0; */
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.408px;
  color: #000066;
  border-radius: 10px

}

.tipbuttonsframe {
  position: relative;
  width: 100%;
  height: 30vh;
  display: flex;
  /* WIDTH and HEIGHT are required */
  justify-content: center;
  align-items: center;
}

.tipwidebuttonframe {
  position: relative;
  width: 100%;
  height: 10vh;
  
  display: flex;
  /* WIDTH and HEIGHT are required */
  justify-content: center;
  align-items: center;
  
}

.username {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.35px;
  color: #1A202C;
}

.businessname{

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  /* identical to box height, or 147% */

  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.35px;

  /* Text // Secondary */

  color: #718096;

}

.topcontent-div {
  position: absolute;
  top: 10px;
  left: 0;
  right: 0;
  bottom: 0;
  margin-top: 0;
  /* background: rgb(209, 199, 56); */
}



/* 70% and 77% worked but buttons were too narrow on phone */

.wrapper-buttonsdiv {
  margin: auto;
  width:100%;
  min-width: 50px; /* Will be AT LEAST 20em wide */
  max-width: 440px;
}


.btn {
  border-radius: 15px;
  position: relative;
  width: 90%;
  height: 12vh;
  left: 0px;
  top: 0px;
  background: #95AEED;

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 2.5vh;
  /* or 117% */

  letter-spacing: 0.35px;

  color: #FFFFFF;
  /* Box Shadow // Medium */

  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
}

.currencySymbol{
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 2.00px;
}

.currencySymbolOtherAmount{
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
    /* border:1px solid #c2b43d; */
  color: #758CE0;
}
.currEquiv{
    letter-spacing: 0.35px;
    color: #D4DEF8;
    font-size: 16px;
}

.widebtn {
  border-radius: 15px;
  position: relative;
  width: 95%;
  height: 90%;
  left: 0px;
  top: 0px;
  background: #95AEED;
  cursor:pointer;

  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 17px;
  /* or 117% */

  letter-spacing: 0.35px;

  color: #FFFFFF;
  /* Box Shadow // Medium */

  filter: drop-shadow(0px 1px 3px rgba(0, 0, 0, 0.1)) drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.06));
}

.searchinput {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.35px;
  padding-left: 30px;
  height: 4vh;
  /* unusual Vue quirk meant that background-image would either have to be computed value or due it with inline style as per above */
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: left center;
  background-color: transparent;
  border:2px solid #b9b9c0; 
  border-radius: 7px;
}

.searchinputvibrant {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  letter-spacing: 0.27px;
  padding-left: 30px;
  height: 5vh;
  /* unusual Vue quirk meant that background-image would either have to be computed value or due it with inline style as per above */
  background-repeat: no-repeat;
  background-size: 20px 20px;
  background-position: left center;
  background-color: white;
  border:2px solid #1a8bfc; 
  /* was #b9b9c0; */
  border-radius: 7px;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
}


.other-amount-input {
  height: 10vh;
  /* width: 4%; */
  padding-left: 0;
  padding-right: 0;
  /* border:1px solid #c2b43d; */

  border: none;
  outline: none; 
  background: none; 


  font-size: 40px;
  
  text-decoration: underline;
  text-decoration-color: #758CE0;
  text-decoration-thickness: 2px;
  text-underline-offset: 6px;
  /* in Arjun's design, he shows a bigger offset which is nice, but unless you set a much greater line height it ceases to be visible, and if you do, then the vertical alignment goes out in safari mobile */
  /* line-height: 80px;   have to set this with a generous margin because we have offset the underline so far down otherwise it gets clipped */

    /* border:2px solid #b9b9c0; */
  /* border-radius: 7px; */
}

/* gets rid of up down arrows on input box number*/
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* gets rid of up down arrows on input box number*/
/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.centreverticaldivsearch {
  height: 8vh;
  width: 65%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
}

.centreverticaldivsearchvibrant {
  height: 12vh;
  width: 85%;
  display: flex;
  margin: auto;
  flex-direction: column;
  justify-content: center;
}

.paymentform {
  position: relative;
  margin: auto; 
  width:90%; 
  outline:0.01em solid black; 
  height: 40vh
}

/* .topspacer { */
  /* position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 30vh;
  width: 100vw; */
  /* background-color: #6c77d4; */
  /* box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2); */

/* } */

.payment-submitted-screen-top {
  position: absolute;
  top: 0vh;
  height: 20vh;
  /* width: 100vw; */
  margin: auto;
  width: 91%;
  min-width: 50px; /* Will be AT LEAST 20em wide */
  max-width: 400px; 
  /* outline:0.1em solid black;  */
  /* background-color: #687cd3; */
}
.success-screen-main {
  position: absolute;
  top: 21vh;
  height: 35vh;
  /* width: 100vw; */
  /* background-color: #68d3d3; */
  margin: auto;
  width: 91%;
  min-width: 50px; /* Will be AT LEAST 20em wide */
  max-width: 400px;
  /* outline:0.1em solid black;  */
}

.success-screen-email {
  position: absolute;
  top: 61vh;
  left: 0;
  right: 0;
  height: 5vh;
  width: 70%;
  margin: auto;
  /* width: 91%; */
  min-width: 100px; 
  max-width: 300px;
  display: flex;
  align-items: center;
  /* background-color: #687cd3; */
  justify-content: center;
  /* outline:0.1em solid black;  */
}

.cancelled-screen-main {
  position: absolute;
  top: 31vh;
  height: 35vh;
  /* width: 100vw; */
  /* background-color: #68d3d3; */
  margin: auto;
  width: 91%;
  min-width: 50px; /* Will be AT LEAST 20em wide */
  max-width: 400px;
  /* outline:0.1em solid black;  */
}

.tip-again {
  position: absolute;
  top: 70vh;
  height: 8vh;
  /* width: 100vw; */
  margin: auto;
  display: block;
  width: 91%;
  min-width: 50px; /* Will be AT LEAST 20em wide */
  max-width: 400px; 
  /* display: flex; */
  align-items: center;
  /* background-color: #687cd3; */
  /* outline:0.1em solid black;  */
}

.willsendemailtext {
  font-family: "Roboto";
  font-style: normal;
  font-weight: normal;
  font-size: 13px;
  line-height: 18px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 0.35px;
  justify-content: center;

  color: #FFFFFF;
}

.standard-textbox {
  
  /* width: 100vw; */
  margin: auto;
  display: block;
  width: 91%;
  min-width: 50px; /* Will be AT LEAST 20em wide */
  max-width: 250px; 
  font-size: 16px;
  /* display: flex; */
  align-items: center;
  height: 4vh;
  padding-left: 12px;
  border:0.3px solid #b9b9c0;
  border-radius: 10px;
}

.input, select, textarea{
    color: #000066;
}

.input::placeholder{
  color: #87BBFD;
}

.notesInfoText{
  color: #000066;
  padding-top: clamp(2px, 1vh, 8px);
  font-size: clamp(2px, 2.5vh, 16px);
}

.notesInfoTextLarger{
  color: #000066;
  padding-top: clamp(2px, 1vh, 8px); 
  font-size: clamp(2px, 2.5vh, 16px);
}

/* Animation screenslideup */

.modal {
  display: flex;
  margin: 0 auto;
  width: 91%;
  min-width: 50px; /* Will be AT LEAST 20em wide */
  max-width: 400px; 
  justify-content: center;
  align-items: center;
  border-radius: 1rem;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  background: rgb(255, 255, 255);
  z-index: 996;
}

.modalcontentwrapper {
  margin: auto; 
  width:90%; 
  outline:0.0em solid black; 
  height: 40vh
}

.modalcontentwrapperwrap {
  margin: auto; 
  width:90%; 
  outline:0.0em solid black; 
  padding-top: 5vh;
  padding-bottom: 5vh;
}


.modal-overlay {
  position: absolute;
  top: 0px;
  right:0;
  left:0;
  margin: auto;
  position: fixed;   /* for some reason, absolute on it own allowed a slight hoirzontal scroll bar effect appeared, no idea why - re-instating fixed fixed it */
  display:flex;
  justify-content:center; 
  align-items:center;
  z-index: 993;
  justify-content: center;
  background: #EDF2F7;
  height: 100%; 
  width: 100%
}


.modal-overlay-canscroll {
  /* position: absolute; */
  top: 0px;
  /* position: fixed; */
  /* position: fixed;   for some reason, absolute on it own allowed a slight hoirzontal scroll bar effect appeared, no idea why - re-instating fixed fixed it */
  display:flex;
  justify-content:center; 
  align-items:center;
  z-index: 993;
  justify-content: center;
  background: #EDF2F7;
  /* height: 100%;  */
  width: 100vw

}


.modal-overlayfrombottomblur {
  position: absolute;
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  margin: auto;
  justify-content:center; 
  align-items:center;
  z-index: 994;
  justify-content: center;
  background: #EDF2F7;

  height: 100%; 
  width: 100vw; 
  background: rgba(0, 0, 0, .7); 
  display:flex; 
  flex-direction:column; 
  flex:1; 
  backdrop-filter: blur(1.5px);

}


.screenslideup-enter-from{
  transform: translateY(100vh);
}
.screenslideup-enter-to{
  transform: translateY(0px);
}
.screenslideup-enter-active{
  transition: all 0.2s ease;
}
.screenslideup-leave-from{
  transform: translateY(0px);
}
.screenslideup-leave-to{
  transform: translateY(100vh);
}
.screenslideup-leave-active{
  transition: all 0.2s ease;
}

.confirmslideup-enter-from{
  transform: translateY(100vh);
}
.confirmslideup-enter-to{
  transform: translateY(0vh);
}
.confirmslideup-enter-active{
  transition: all 0.3s linear;
}
.confirmslideup-leave-from{
  transform: translateY(0vh);
}
.confirmslideup-leave-to{
  transform: translateY(100vh);
}
.confirmslideup-leave-active{
  transition: all 0.3s ease;
}

.fade-enter-from{
  opacity: 0;
}
.fade-enter-to{
  opacity: 1;
}
.fade-enter-active{
  transition: all 0.2s linear;
}
.fade-leave-from{
  opacity: 1;
}
.fade-leave-to{
  opacity: 0;
}
.fade-leave-active{
  transition: all 0.2s ease;
}

/* END Animation screenslideup  */

</style>
